import mime from 'mime';
import axios from 'axios';
import IS from '../../Utils/IS';
import ID from '@tools/Utils/ID';
import { toJson } from '../../Utils/Object';
import useStore from '@tools/Store/useStore';
import { RequestOptions } from '../useFetch';
import useLocalCache from '../useLocalCache';
import useFetch, { authFetch } from '../useFetch';
import { fileNameCorrector } from '@tools/Utils/File';
import { useState, useCallback, useMemo } from 'react';
import { CONFIG } from './../../../App/Config/constants';
import { setLocalCache } from '../../Store/actions/LocalCacheActions';
import { UC_Loadings_type, UC_Options, UC_getItems_Params, UC_putItem_Options } from './Types';
import { removeUploadProgress, setUploadProgress } from '@tools/Store/actions/DashboardActions';

export const uploadControllers: Record<string, AbortController> = {};

const useClass = <T extends OBJECT>(CLASS_NAME: SUG<CLASS_NAMES>, options?: UC_Options) => {
	const URL = getClassUrl(CLASS_NAME);
	const PRIMARY_KEY = options?.primaryKey ?? `${CLASS_NAME}_ID`;
	const [loadings, setLoadings] = useState<UC_Loadings_type>({});
	const { Get, Post, data } = useFetch<T>(URL, { base: false });

	//* --------------------- 👇Cache --------------------------
	const { dispatch } = useStore();
	const { [CLASS_NAME]: LocalData }: any = useLocalCache();

	//?---------------------- 👇Utils -------------------------

	const _emitLoading = (name: keyof UC_Loadings_type, value: boolean) => setLoadings(s => ({ ...s, [name]: value }));

	const _extractBody = async (obj?: Record<string, any>) => {
		if (!obj) return undefined;

		//* Add all the blob files to the formData
		for (const [, value] of Object.entries(obj || {}))
			if (IS.object(value)) {
				let entities = Object.entries(value);
				for (const [k, v] of entities) {
					if (IS.blob(v)) {
						try {
							const newFile: any = new Blob([v]);
							const imageURL = window.URL.createObjectURL(newFile);
							let isBig: boolean | undefined;
							if (mime.getType(obj?.item?.name)?.includes('image')) {
								isBig = ((await getImageDimensions(imageURL)) as any)?.isBig;
							}

							newFile.lastModified = new Date();
							let name = fileNameCorrector(obj?.item?.name || '');
							newFile.name = name;

							if (obj.item) obj.item.name = name;

							let { url, s3_key, message } = (
								await authFetch.post('/endpoints/get-upload-url', {
									body: { fileName: name, className: CLASS_NAME, metadata: [{ isbig: isBig }] },
								})
							).json;
							if (!url) throw new Error(message || 'cannot connect to AWS please contact support');
							const uploadId = new ID(4).generate();
							const controller = new AbortController();
							uploadControllers[uploadId] = controller;
							let { status, statusText } = await axios.request({
								method: 'PUT',
								headers: {
									'Content-Disposition': 'attachment; filename="' + name + '"',
									'Content-Type': mime.getType(obj?.item?.name) || undefined,
								},
								url,
								data: newFile,
								signal: controller.signal,
								onUploadProgress: p => {
									let reduxObject = {
										name: name,
										id: uploadId,
										data: {
											controller,
											percent: p?.progress || 0,
											speed: p?.rate || 0,
											total: p?.total || 0,
											downloaded: p?.loaded || 0,
										},
									};
									dispatch(setUploadProgress(reduxObject));
									if (p?.progress === 1) {
										setTimeout(() => {
											dispatch(removeUploadProgress({ id: uploadId }));
										}, 5000);
									}
								},
							});
							if (status === 200) {
								if (!obj?.item) obj.item = {};
								obj.item[k + '_s3'] = { s3_key, CLASS_NAME, isBig };
							} else throw new Error(statusText);
						} catch (error: any) {
							console.log(error);
							throw new Error(error?.message);
						}
						delete value?.[k];
					}
				}
			}
		return obj;
	};

	//?----------------- 👇Main CRUD Methods --------------------

	const getItem = async (id: string, params: OBJECT = {}) => {
		_emitLoading('getItem', true);
		let result;
		try {
			result = await Get({ url: `${URL}/${id}`, params });
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('getItem', false);
		}
		return result as OBJECT & { item?: T };
	};

	const getItems = async (getItemsOptions: UC_getItems_Params<T> = {}) => {
		_emitLoading('getItems', true);
		const { page, limit, props, params, contains, parse_query } = getItemsOptions;
		let result;
		try {
			const queryParams = { _page: page, _limit: limit, _props: props, _contains: contains, ...params };
			if (!!parse_query) (queryParams as any)._query = JSON.stringify(parse_query.toJSON());
			if (!LocalData || !!parse_query) result = await Get({ url: URL, params: queryParams });
			else {
				console.warn(`Please use '${CLASS_NAME}.items' as state instead of calling 'getItems'`);
				let items: any[] = LocalData || [];

				//? Filtering LocalData

				if (limit) items = items?.slice(((page || 1) - 1) * limit, (page || 1) * limit);

				if (props) items = items?.map(item => toJson(item, { only: props as any }));

				if (params)
					items = items?.filter(item => {
						for (const [key, value] of Object.entries(params || {})) if (item[key] !== value) return false;
						return true;
					});

				if (contains)
					items = items?.filter(item => {
						for (const [key, value] of Object.entries(contains || {}))
							if (IS.string(item?.[key])) return item?.[key]?.includes(value);
						return false;
					});

				result = { items, count: items?.length };
			}
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('getItems', false);
		}

		return result as OBJECT & {
			items?: T[];
			count?: number;
			items_ref?: React.MutableRefObject<T[]>;
			params?: Omit<UC_getItems_Params<T>, 'params'>;
		};
	};

	const putItem = async (item: T, putItemOption: UC_putItem_Options = {}) => {
		_emitLoading('putItem', true);
		const { ID, action } = putItemOption;
		let result;
		try {
			const body = await _extractBody({ action, item: { ...item, ...(!!ID ? { [PRIMARY_KEY]: ID } : {}) } });
			result = await Post({ url: URL + '/putItem', body });
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('putItem', false);
		}
		return result as OBJECT & { item?: T; message?: string; action?: 'add' | 'edit' };
	};

	const addItem = async (item: T) => {
		_emitLoading('addItem', true);
		let result;
		try {
			const body = await _extractBody({ action: 'add', item });
			result = await Post({ url: URL + '/putItem', body });
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('addItem', false);
		}
		return result as OBJECT & { item?: T; message?: string };
	};

	const editItem = async (id: string, item: T, params: OBJECT = {}) => {
		_emitLoading('editItem', true);
		let result;
		try {
			const body = await _extractBody({ action: 'edit', item: { ...item, [PRIMARY_KEY]: id } });
			result = await Post({ url: URL + '/putItem', body, params });
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('editItem', false);
		}
		return result as OBJECT & { item?: T; message?: string };
	};

	const deleteItem = async (id: string, body?: OBJECT<any>) => {
		_emitLoading('deleteItem', true);
		let result;
		try {
			body = { item: { [PRIMARY_KEY]: id }, ...(body || {}) };
			result = await Post({ url: URL + '/deleteItem', body });
			deleteLocalItem(id);
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('deleteItem', false);
		}
		return result as OBJECT & { item?: T; message?: string; action?: 'delete' };
	};

	const deleteLocalItem = (id: string) => {
		if (!LocalData?.[id]) return;
		dispatch(
			setLocalCache({
				deleted: [id],
				src: 'frontend',
				partialUpdate: true,
				class_name: CLASS_NAME,
			})
		);
	};

	//?------------ 👇Utility functions --------------------

	const getSchema = async () => {
		_emitLoading('getSchema', true);
		let result;
		try {
			result = await Get({ url: `${URL}/schema` });
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('getSchema', false);
		}
		return result as OBJECT & {
			schema?: {
				className: string;
				fields: { [prop: string]: { type: string; required?: boolean } };
			};
		};
	};

	const getCount = async () => {
		_emitLoading('getCount', true);
		let result;
		try {
			result = await Get({ url: `${URL}/count` });
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('getCount', false);
		}
		return result?.count as number;
	};

	//?----------------- 👇Endpoints -----------------------

	const { Get: EGet, Post: EPost } = useFetch<T>(URL, { base: false });

	const get = async (path: string, options?: RequestOptions) => {
		if (!path) return {} as OBJECT;
		_emitLoading('get', true);
		let result;
		try {
			result = await EGet({ url: `${URL}${path}`, ...options });
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('get', false);
		}
		return result as OBJECT;
	};

	const post = async (path: string, body?: any) => {
		_emitLoading('post', true);
		let result;
		try {
			result = await EPost(await _extractBody({ url: `${URL}${path}`, body }));
		} catch (e) {
			throw e;
		} finally {
			_emitLoading('post', false);
		}
		return result as OBJECT;
	};

	//?------------------------------------------------------

	const Class = {
		get,
		post,
		getItem,
		putItem,
		addItem,
		getItems,
		editItem,
		loadings,
		getCount,
		getSchema,
		deleteItem,
		deleteLocalItem,
		items: useMemo<T[] | undefined>(() => Object.values(LocalData || {}), [LocalData]),
		item: useCallback((ID?: string) => (!!ID ? LocalData?.[ID] : undefined), [LocalData]),
	};

	return { Class, data, classLoadings: loadings, PRIMARY_KEY, LocalData };
};

const getImageDimensions = (imageUrl: any) => {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.src = imageUrl;
		let isBigImage = false;
		image.onload = () => {
			const isBig = (image?.width || 0) > 10000 && (image?.height || 0) > 10000;
			if (isBig) isBigImage = true;
			window.URL.revokeObjectURL(imageUrl);
			resolve({
				width: image.width,
				height: image.height,
				isBig: isBigImage,
			});
		};
	});
};

export const getClassUrl = (className: string) => `${CONFIG.SERVER}/classes/${className}`;

export default useClass;
