/* eslint-disable no-extend-native */
import moment from 'moment';

//? ------------------------------- Types ----------------------------------------

declare global {
	interface Date {
		toSLASHString(): string;
	}
}

//? ---------------------------- Functions ----------------------------------------

Date.prototype.toSLASHString = function () {
	const m = moment(this);
	return m?.format('MM/DD/YYYY');
};

// --------------------------------------------------------------------------------

export default global;
