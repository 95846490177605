import { useEffect } from 'react';
import useAccount from './useAccount';
import { Notify } from '../Utils/React';
import { useCallback, useState } from 'react';
import useLoading from './useLoading/useLoading';
import { CONFIG } from '../../App/Config/constants';

//? ----------------- 👇 Initial data ------------------------------------

const ReqInit: RequestOptions = { base: true, throwError: false };

//? -------------------- 👇 useFetch -------------------------------------

const useFetch = <T extends object | any = any>(fetchUrl?: string, fetchOptions?: RequestOptions) => {
	const loadings = useLoading();
	const { user, tokenRef } = useAccount();
	const controller = new AbortController();
	const [data, setData] = useState<null | T>(null);
	const [error, setError] = useState<boolean | any>(false);

	//? Cancel request on unmounting the component
	useEffect(() => {
		return () => {
			!!loadings.is('all') && controller.abort();
		};
	}, [loadings.is('all')]);

	const setLoadingByMethod = (isLoading: boolean, method?: RequestMethods) => {
		loadings.set('all', isLoading);
		if (method) loadings.set(method, isLoading);
	};

	const fetchByMethod = useCallback(
		(method: RequestMethods) => async (options?: RequestOptions) => {
			let json;

			setData(null);
			setError(false);
			setLoadingByMethod(true, method);

			const mergedOptions = { ...fetchOptions, ...options, signal: controller.signal } as RequestOptions;
			const url = mergedOptions?.url || fetchUrl;
			if (!url) return;

			try {
				const ext_options = { ...mergedOptions, token: tokenRef?.current, method: method };
				const { json: res_json } = await authFetch(url, ext_options);
				json = res_json;
				setData(json);
				setLoadingByMethod(false, method);
				if (json?.error) throw new Error(`${json?.message}`);
			} catch (e) {
				setError(e);
				setData(null);
				setLoadingByMethod(false, method);
				throw e;
			}

			return json as T;
		},
		[fetchOptions, fetchUrl, CONFIG, user]
	);

	const LOADING = {
		loading: loadings.is('all'),
		getLoading: loadings.is('GET'),
		putLoading: loadings.is('PUT'),
		postLoading: loadings.is('POST'),
		deleteLoading: loadings.is('DELETE'),
		loadings: {
			all: loadings.is('all'),
			get: loadings.is('GET'),
			put: loadings.is('PUT'),
			post: loadings.is('POST'),
			delete: loadings.is('DELETE'),
		},
	};

	const FETCH_METHODS = {
		Get: fetchByMethod('GET'),
		Put: fetchByMethod('PUT'),
		Post: fetchByMethod('POST'),
		Delete: fetchByMethod('DELETE'),
	};

	return { controller, data, error, ...LOADING, ...FETCH_METHODS };
};

//? --------------------- 👇 Utils ---------------------------------------

const extendOptions = (options: RequestOptions) => ({ ...ReqInit, ...options });

export const authFetch = async (url: string, options?: RequestOptions) => {
	let { token = window?.user_token, body } = options || {};
	let response: Response | undefined, xhr: XMLHttpRequest, status_code: any, json: any;

	if (!navigator.onLine) {
		//! if the internet is offline, throw an error
		const msg = 'You are offline. Please check your internet connection.';
		Notify.error(msg, { duration: 4000, icon: 'l-wifi-slash' });
		throw new Error(msg);
	}

	//? Handling Json and FormData
	const isFormData = body instanceof FormData;
	if (body && typeof body === 'object' && !isFormData) body = JSON.stringify(body);

	//? Options
	const fetchOptions = extendOptions({ ...options, body });
	fetchOptions.headers = {
		...fetchOptions.headers,
		...(!!isFormData ? { 'Content-Type': 'multipart/form-data' } : {}),

		//! Sending the token using headers disabled due to prevent of preflight requests
		// ...(!!token ? { Authorization: 'Bearer ' + token } : {}),
	};

	//? Handling Query params
	let params: any = fetchOptions?.params;
	if (!!token) params = { ...(params || {}), _auth: token };
	let stringParam;
	if (params) {
		let entries: any[] = Object.entries(params || {}).filter(item => item[1] !== undefined);
		entries = entries.map(item => `${item[0]}=${item[1]}`);
		stringParam = '?' + entries.join('&');
	}

	//? Final Request Url
	let req_url = (fetchOptions.base ? CONFIG.SERVER : '') + url + (stringParam || '');

	//? Execute request using fetch or XMLHttpRequest
	try {
		if (!isFormData) {
			response = await fetch(req_url, fetchOptions as RequestInit);
			status_code = response?.status;
			try {
				json = await response!!?.json();
			} catch {
				// do nothing
			}
		} else {
			try {
				xhr = new XMLHttpRequest();
				fetchOptions?.signal?.addEventListener('abort', () => {
					xhr.abort();
				});
				xhr?.open('POST', req_url);
				xhr?.send(fetchOptions?.body as any);
				await new Promise((resolve, reject) => {
					xhr.onload = () => resolve(xhr);
					xhr.onerror = ((_: any, error: any) => reject(error)) as any;
					xhr.upload.onprogress = e => {
						console.log('upload.onprogress', e);
					};
				});
				status_code = xhr?.status;
				json = JSON.parse(xhr?.response);
			} catch (e: any) {
				console.log('🔴authFetch > XHR', e);
				throw e;
			}
		}

		if (status_code >= 400) throw new Error(`Request Error ${status_code}`);
	} catch (e) {
		if (options?.throwError) throw e;
	}

	return { json, response };
};

const authFetchByMethod = (method: RequestMethods) => async (url: string, options?: RequestOptions) =>
	await authFetch(url, { method: method, ...options });

authFetch.get = authFetchByMethod('GET');
authFetch.put = authFetchByMethod('PUT');
authFetch.post = authFetchByMethod('POST');
authFetch.delete = authFetchByMethod('DELETE');

export const objectToFormData = (obj: { [key: string]: string | Blob | undefined }) => {
	const formData = new FormData();
	Object.keys(obj || {}).forEach(key => obj[key] && formData.append(key, obj[key]!!));
	return formData;
};

//? ----------------------- 👇 Types ------------------------------------

export type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type RequestOptions = Omit<RequestInit, 'body'> & {
	url?: string;
	base?: boolean;
	token?: string;
	params?: object;
	throwError?: boolean;
	body?: object | string | BodyInit;
};

//? ----------------------- 👇 Export ------------------------------------

export default useFetch;
