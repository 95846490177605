import './index.scss';
import { Loader } from 'rsuite';
import { FC, useEffect, useState } from 'react';
import DMP_LOGO from '../../Assets/icons/dmp-logo.svg';

const LoadingOverlay: FC<{ loading?: boolean }> = props => {
	const { loading = true } = props || {};

	const [isLoading, setIsLoading] = useState(loading);

	useEffect(() => {
		setTimeout(() => setIsLoading(loading), 20);
	}, [loading]);

	if (!isLoading) return null;

	return (
		<div className='loading-overlay-layout'>
			<Loader speed='slow' />
			<div className='center-box'>
				<img className='logo' alt='logo' src={DMP_LOGO} />
			</div>
		</div>
	);
};

export default LoadingOverlay;
