//? ------------------------------ Configs --------------------------------------

const BASE = {
	BASE_URL: '/',
	APP_SHORT_NAME: 'DMP',
	APP_NAME: 'DISCOUNT MARKETING PRODUCTS',
};

const PRODUCTION = {
	_mode: 'PRODUCTION' as typeof window.MODE,
	WSS: 'wss://back.dmpmarket2.com',
	SERVER: 'https://back.dmpmarket2.com',
	FRONTEND: 'https://dmpmarket2.com',
	STRIPE_Public_Key: 'pk_live_dvI31UkqUifHhZLiGUM8enGs',
};

const DEVELOPMENT = {
	_mode: 'DEVELOPMENT' as typeof window.MODE,
	WSS: 'wss://back.dmpmarket2dev.com',
	SERVER: 'https://back.dmpmarket2dev.com',
	FRONTEND: 'https://dmpmarket2dev.com',
	STRIPE_Public_Key: 'pk_test_Fwjnb9sXzUI4gOCvBO4kzIhL',
};

const LOCAL = {
	_mode: 'LOCAL' as typeof window.MODE,
	WSS: 'wss://back.dmpmarket2dev.com',
	SERVER: 'https://back.dmpmarket2dev.com',
	// SERVER: 'http://127.0.0.1:1337',
	FRONTEND: 'https://dmpmarket2dev.com',
	STRIPE_Public_Key: 'pk_test_Fwjnb9sXzUI4gOCvBO4kzIhL',
};

//? --------------------- Config selection ----------------------------------------

export type CONFIG_TYPE = typeof BASE & typeof PRODUCTION & typeof DEVELOPMENT & typeof LOCAL;

export const CONFIG: CONFIG_TYPE = { ...BASE, ...(({ LOCAL, PRODUCTION, DEVELOPMENT } as any)[window.MODE as any] || {}) };

window.CONFIG = CONFIG;
