import useClass from '../useClass';
import useFetch from '../useFetch';
import useAccount from '../useAccount';
import { useEffect, useRef } from 'react';
import { PROMISE } from 'ahq-front-tools';
import { isAfter } from '@tools/Utils/Date';
import { Notify } from '@tools/Utils/React';
import useLocalCache from '../useLocalCache';
import useStore from '@tools/Store/useStore';
import { promiseQueueRunner } from '@tools/Utils/Async';
import useLoading from '@tools/Hooks/useLoading/useLoading';
import { logout } from '@tools/Store/actions/AccountActions';
import { clearLocalStorage, setTotalCount } from '../../Store/actions/LocalCacheActions';
import { setLoadings, clearClassData, setLocalCache } from '@tools/Store/actions/LocalCacheActions';

let isMultiUpdating = false;

const useCacheUpdater = () => {
	const { Get } = useFetch();
	const loading = useLoading();
	const { dispatch } = useStore();
	const { user, login_date } = useAccount();
	const { Class: LIVE_CLASS } = useClass('LIVE_CLASS');
	const { last_update, liveUpdateStatus, no_caching_classes } = useLocalCache();

	//?--------------------- Vars -------------------------------------------------//

	const lastUpdateRef = useRef(last_update);
	const liveUpdateStatusRef = useRef(liveUpdateStatus);

	//?------------------- useEffects ---------------------------------------------//

	useEffect(() => {
		lastUpdateRef.current = last_update;
		liveUpdateStatusRef.current = liveUpdateStatus;
	}, [last_update, liveUpdateStatus]);

	//?------------------- Utils --------------------------------------------------//

	//? Check for update
	const check = async (className?: string, last_update?: string, cache_date?: string) => {
		loading.on('check');
		try {
			if (className) {
				//* single update
				const local_last_update = lastUpdateRef?.current?.[className];
				if (!local_last_update || !cache_date) return update(className);
				const is_cache_update = isAfter(cache_date, local_last_update);
				await update(className, last_update, is_cache_update);
			} else {
				//* multi update
				if (isMultiUpdating) return;
				isMultiUpdating = true;
				const { items = [] } = (await LIVE_CLASS.getItems()) || {};
				const entries = Object.entries((items as any) || {});
				const promises = entries?.map((entry: any) => async () => {
					try {
						const [class_name, { last_update, last_refresh_cache, count = -1, no_caching }] = entry || {};
						if (+(count || 0) >= 0) dispatch(setTotalCount({ className: class_name as any, count, no_caching }));
						if (no_caching) return;
						const local_last_update = lastUpdateRef?.current?.[class_name];
						if (!local_last_update) return await update(class_name);
						const is_local_update = isAfter(last_update, local_last_update);
						const is_cache_update = isAfter(last_refresh_cache, local_last_update);
						const is_update_needed = is_cache_update || is_local_update;
						if (is_update_needed) await update(class_name, last_update, is_cache_update);
					} catch (error: any) {
						console.error(`🔴useCacheUpdater > check > promises: ${entry?.class_name}`, `\n\t${error}`);
					}
				});
				await PROMISE.batchRunner(promises, 15);
				dispatch(setLoadings({ init: false, refresh: false }));
				loading.off('check');
				isMultiUpdating = false;
			}
		} catch (error) {
			console.error('🔴useCacheUpdater > check', `\n\t${error}`);
		}
	};

	//? Check only if the live update status is off
	const checkIfLiveOff = async (className?: CLASS_NAMES) => {
		loading.on('check');
		// if (isMultiUpdating || liveUpdateStatusRef.current) return loading.off('check');
		await check(className);
		isMultiUpdating = false;
		setTimeout(() => loading.off('check'), 0);
	};

	//? Update handler
	const update = async (class_name: string, last_update?: string, is_refresh_cache?: boolean) => {
		if (!class_name || !!no_caching_classes?.[class_name] || !!NO_CACHING_CLASSES?.[class_name]) return;
		const url = `/classes/${class_name}`;
		const query = is_refresh_cache ? {} : { params: { last_update: lastUpdateRef?.current?.[class_name] } };
		try {
			const res = await Get({ url, ...query });
			const { items: data = [], deleted = [] } = res || {};

			const is_updated = isCurUserModified(class_name, data, deleted);
			if (is_updated) return;

			if (is_refresh_cache) dispatch(clearClassData(class_name));
			dispatch(
				setLocalCache({
					data,
					deleted,
					class_name,
					last_update,
					src: 'backend',
					partialUpdate: !is_refresh_cache,
				})
			);
		} catch (error) {
			console.error(`Problem in fetching ${class_name}`, error);
		}
	};

	//? Clear and refresh the whole cache
	const refresh = async () => {
		loading.on('refresh');
		dispatch(clearLocalStorage({ refresh_loading: true, init_loading: false }));
		const { items = [] } = (await LIVE_CLASS.getItems()) || {};
		const entries = Object.entries((items as any) || {});
		const promises = entries?.map((entry: any) => async () => {
			const class_name = entry?.[0];
			const { no_caching, count } = entry?.[1] || {};
			if (!!no_caching) {
				if (count !== -1) dispatch(setTotalCount({ className: class_name as any, count, no_caching }));
				return;
			}
			await update(class_name, undefined, true);
		});
		await promiseQueueRunner(promises, 10);
		dispatch(setLoadings({ refresh: false }));
		loading.off('refresh');
	};

	//? If the role changed, logout the user
	const isCurUserModified = (CLASS_NAME: string, items: any[], deleted: any[]) => {
		if (CLASS_NAME !== 'SYSTEM_USER') return;
		const CUR_USER_ID = user?.ID || user?.SYSTEM_USER_ID;
		if (!CUR_USER_ID) return;

		//? User deleted
		if (!!deleted?.find?.(i => i === CUR_USER_ID)) {
			dispatch(logout());
			dispatch(clearLocalStorage({ init_loading: true }));
			Notify.error('Your account has been removed.', {
				duration: 6000,
				icon: 's-user-xmark',
				placement: 'bottomCenter',
				className: 'acc-modification-notify',
			});
			return true;
		}

		//? User updated
		let user_item = items?.find?.(i => i?.SYSTEM_USER_ID === CUR_USER_ID);
		if (!!user_item && isAfter(user_item?.updatedAt, login_date!!)) {
			dispatch(logout());
			dispatch(clearLocalStorage({ init_loading: true }));
			Notify.info('Your account info has been updated.', {
				duration: 6000,
				icon: 's-user-pen',
				placement: 'bottomCenter',
				className: 'acc-modification-notify',
			});
			return true;
		}
	};

	//----------------------------------------------------------------------------//

	return {
		check,
		update,
		refresh,
		loading,
		liveUpdateStatus,
		checkWithoutLiveUpdateStatus: checkIfLiveOff,
	};
};

export const NO_CACHING_CLASSES: any = { CUSTOMER: true, INVENTORY: true, ORDER_VIEW: true, ORDER_DRAFT: true };

export default useCacheUpdater;
