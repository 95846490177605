import { createReducer } from '@reduxjs/toolkit';
import { setLoading, setUploadProgress, setRouterLoading, removeUploadProgress } from '../actions/DashboardActions';
import { uploadControllers } from '@tools/Hooks/useClass';

type UploadProgressType = { id: string, data:any, name: string };
type InitStateType = { isLoading: boolean; uploadProgress: { [id: string]: UploadProgressType }; isRouterLoading: boolean };

const initState: InitStateType = { isLoading: false, uploadProgress: {}, isRouterLoading: false };

const DashboardReducer = createReducer(initState, {
	//* loading
	[setLoading.type]: (state, { payload }) => ({ ...state, isLoading: payload }),

	//* upload progress
	[setUploadProgress.type]: (state, { payload }) => {
		let oldUploads: { [id: string]: UploadProgressType } = {...state?.uploadProgress};
		oldUploads[payload?.id] = { ...payload }
		return ({ ...state, uploadProgress: oldUploads })
	},

	[removeUploadProgress.type]: (state, { payload }) => {
		let oldUploads: { [id: string]: UploadProgressType } = {...state?.uploadProgress};
		delete oldUploads[payload?.id]
		delete uploadControllers[payload?.id]
		return ({ ...state, uploadProgress: oldUploads })
	},


	//* Router loading
	[setRouterLoading.type]: (state, { payload }) => ({ ...state, isRouterLoading: payload }),
});

export default DashboardReducer;
