import { createAction } from '@reduxjs/toolkit';

export const clearClassData = createAction<string>('clearClassData');

export const setDisconnected = createAction<boolean>('setDisconnected');

export const setLocalCache = createAction<LocalActionEntryType>('setLocalCache');

export const setLiveUpdateStatus = createAction<boolean>('setLiveUpdateStatus');

export const setLoadings = createAction<{ init?: boolean; refresh?: boolean }>('setLoadings');

export const setTotalCount = createAction<{ className: CLASS_NAMES; count: number; no_caching?: boolean }>('setTotalCount');

export const clearLocalStorage = createAction<{ init_loading?: boolean; refresh_loading?: boolean }>('clearLocalStorage');

export type LocalActionEntryType = {
	data?: any;
	deleted?: string[];
	class_name?: string;
	last_update?: string;
	partialUpdate?: boolean;
	src?: 'backend' | 'frontend';
};
