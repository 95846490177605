import preval from 'preval.macro';
import { useEffect } from 'react';
import useStore from '@tools/Store/useStore';
import { removeChunksCache } from '../../Utils/Cache';
import { logout } from '../../Store/actions/AccountActions';
import { Notify } from '@tools/Utils/React';

//* Build time versioning
const BUILD_VERSION = preval`module.exports = new Date().getTime();`;
(window as any).BUILD_VERSION = BUILD_VERSION;

const useCacheInvalidator = () => {
	const { dispatch } = useStore();

	useEffect(() => {
		if (window.MODE === 'LOCAL') return;

		const check = () => {
			if (typeof localStorage === 'undefined') return false;
			try {
				let storedVersion = +(localStorage.getItem('BUILD_VERSION') || 0);

				if (!storedVersion) {
					localStorage.setItem('BUILD_VERSION', `${BUILD_VERSION}`);
					storedVersion = +BUILD_VERSION;
				}

				if (storedVersion < BUILD_VERSION) {
					dispatch(logout());
					removeChunksCache();
					resetLocalStorage();

					Notify.info(
						<div>
							<p>System has been updated.</p>
							<small>Please login again.</small>
						</div>,
						{ icon: 'd-sparkles' }
					);
				}
				return true;
			} catch (error) {
				console.error('Error accessing localStorage:', error);
			}
		};

		const intervalId = setInterval(() => {
			if (check()) clearInterval(intervalId);
		}, 2000);

		return () => clearInterval(intervalId);
	}, []);
};

export const resetLocalStorage = () => {
	localStorage.clear();
	setTimeout(() => localStorage.setItem('BUILD_VERSION', `${BUILD_VERSION}`), 500);
};

export default useCacheInvalidator;
